import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead, Snackbar, Box, CircularProgress } from '@mui/material';
import VersionRow from './VersionRow';
import { SlideSetVersion } from '../../../api/slideSetVersion';
import { CopyOverMethodsContext } from '../context/CopyOverMethodsContext';
import PublishingVersionContext from '../../../contexts/is-slide-set-publishing-context';

type Props = {
  versions: SlideSetVersion[];
};

const VersionList = ({ versions }: Props): JSX.Element => {
  const [versionIdToCopyOverMethods, setVersionIdToCopyOverMethods] = React.useState<number | undefined>(undefined);
  const { publishingVersion } = useContext(PublishingVersionContext);
  const [open, setOpen] = useState(false);

  const contextValue = useMemo(
    () => ({
      versionIdToCopy: versionIdToCopyOverMethods,
      openCopyDialog: (versionId: number) => setVersionIdToCopyOverMethods(versionId),
      closeCopy: () => setVersionIdToCopyOverMethods(undefined),
    }),
    [versionIdToCopyOverMethods],
  );

  useEffect(() => {
    setOpen(publishingVersion.isPublishing);
  }, [publishingVersion.isPublishing]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell>Last edited</TableCell>
            <TableCell>State</TableCell>
            <TableCell>State History</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="version-table-rows">
          <CopyOverMethodsContext.Provider value={contextValue}>
            {versions.map(version => (
              <VersionRow key={version.id} version={version} />
            ))}
          </CopyOverMethodsContext.Provider>
        </TableBody>
      </Table>
      <Snackbar
        open={open}
        message={
          <Box display="flex" alignItems="center">
            <span>{`Version ${publishingVersion.versionNumber} is being published`}</span>
            <CircularProgress size={20} style={{ marginLeft: '10px' }} />
          </Box>
        }
      />
    </>
  );
};

export default VersionList;
