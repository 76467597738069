import { VisibilityOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { SlideSetVersion, putSlideSetVersionState } from '../../../api/slideSetVersion';
import { SlideSetVersionState } from '../../../api/slideSet';
import SlideSetVersionContext from '../../../contexts/slideset-version-context';
import SlidingConfirmation from '../../../components/sliding-confirmation';
import PublishingVersionContext from '../../../contexts/is-slide-set-publishing-context';

type PublishProps = {
  version: SlideSetVersion;
};

const PublishAction = ({ version }: PublishProps) => {
  const { versions } = useContext(SlideSetVersionContext);
  const { publishingVersion, fetchVersion } = useContext(PublishingVersionContext);
  const { nodeId, moduleId, setId } = useParams();

  const hasPublishedSlidesetVersion = versions.some(v => v.state === SlideSetVersionState.PUBLISHED);
  const isDisabled =
    version.state === SlideSetVersionState.PUBLISHED ||
    version.state === SlideSetVersionState.ARCHIVED ||
    publishingVersion.isPublishing;

  const handlePublish = async () => {
    if (!moduleId || !nodeId || !setId) return;
    await putSlideSetVersionState(moduleId, nodeId, setId, version.id.toString(), SlideSetVersionState.PUBLISHED);
    // not 0 so it will initally refetch the versions
    fetchVersion(1);
  };

  const button = (
    <IconButton
      onClick={handlePublish}
      disabled={isDisabled}
      id="visibility"
      color="primary"
      size="large"
      data-testid="publish-button"
    >
      <span title="Publish">
        <VisibilityOutlined />
      </span>
    </IconButton>
  );

  if (hasPublishedSlidesetVersion) {
    return (
      <SlidingConfirmation
        title="Warning: replacing current set"
        context="This slide set version will replace the current published slide set version, which will be archived."
        disagreeAction="Cancel"
        agreeAction="Publish"
      >
        {button}
      </SlidingConfirmation>
    );
  }

  return button;
};

export default PublishAction;
