import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { getIsSlideSetPublishing, PublishingVersion } from '../api/slideSet';
import retryAfter from '../utils/retry-after';
import SlideSetVersionContext from './slideset-version-context';

const RETRY_COUNT = 20;
const DELAY_IN_SECONDS = 5;

type SlideSetVersionsContextValue = {
  publishingVersion: PublishingVersion;
  fetchVersion: (retryCount?: number) => void | Promise<void>;
};

const defaultSlideSetVersionsContextValue: SlideSetVersionsContextValue = {
  publishingVersion: { isPublishing: true },
  fetchVersion: () => {},
};

const PublishingVersionContext = createContext<SlideSetVersionsContextValue>(defaultSlideSetVersionsContextValue);

export default PublishingVersionContext;

export const PublishingVersionProvider = ({ children }) => {
  const [version, setVersion] = useState<PublishingVersion | null>(null);
  const { refreshVersions } = useContext(SlideSetVersionContext);
  const { moduleId, nodeId, setId } = useParams();
  const timeoutId = useRef<number>();

  const fetchVersion = useCallback(
    async (retryCount = 0, current = true) => {
      if (!moduleId || !nodeId || !setId) return;

      if (retryCount >= RETRY_COUNT) {
        return;
      }

      const result = await getIsSlideSetPublishing(moduleId, nodeId, Number(setId));

      if (!current) return;

      setVersion(result);

      if (!result.isPublishing && retryCount !== 0) {
        refreshVersions();
      }

      if (result.isPublishing) {
        timeoutId.current = retryAfter(async () => {
          await fetchVersion(retryCount + 1);
        }, DELAY_IN_SECONDS * 1000);
      }
    },
    [moduleId, nodeId, setId, refreshVersions],
  );

  useEffect(() => {
    let current = true;

    fetchVersion(0, current);

    return () => {
      current = false;
      timeoutId.current = undefined;
    };
  }, [fetchVersion]);

  const contextValue = useMemo(
    () => ({
      publishingVersion: version || { isPublishing: true },
      fetchVersion,
    }),
    [version, fetchVersion],
  );

  return <PublishingVersionContext.Provider value={contextValue}>{children}</PublishingVersionContext.Provider>;
};
